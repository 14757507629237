import {
  PRODUCT_ADD_NEW,
  PRODUCT_ADD_NEW_ERROR,
  PRODUCT_ADD_NEW_SUCCESS,
  PRODUCT_GET_BY_ID,
  PRODUCT_GET_BY_ID_ERROR,
  PRODUCT_GET_BY_ID_SUCCESS,
  PRODUCT_GET_PAGING,
  PRODUCT_GET_PAGING_ERROR,
  PRODUCT_GET_PAGING_SUCCESS,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_ERROR,
  PRODUCT_UPDATE_SUCCESS,
} from './actionType';
// item
const initialState = {
  item: null,
  items: [],
  loading: false,
  error: null,
  itemCount: 0,
  pageCount: 0,
  total: 0,
  threeCount: 0,
  sixCount: 0,
  moreCount: 0,
  noExpiringCount: 0,
   
  threeMonthsIds: [],
  sixMonthsIds: [],
  moreSixMonthsIds: [],
  noExpiringIds: [],
};

export default function initState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_ADD_NEW:
    case PRODUCT_GET_PAGING:
    case PRODUCT_UPDATE:
      return {
        ...state,
        loading: true,
        error: null,
        item: null,
      };

    case PRODUCT_GET_PAGING_SUCCESS:
      const { itemCount, pageCount, data, threeMonthsIds, sixMonthsIds, moreSixMonthsIds, noExpiringIds,
        threeCount,
        sixCount,
        moreCount,
        noExpiringCount,
       } = payload;
      return {
        ...state,
        loading: false,
        error: null,
        itemCount,
        pageCount,
        items: data,
        threeCount,
        sixCount,
        moreCount,
        noExpiringCount,
        threeMonthsIds, 
        sixMonthsIds, 
        moreSixMonthsIds,
        noExpiringIds,
      };

    case PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // item: payload
      };

    case PRODUCT_GET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case PRODUCT_ADD_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        item: payload,
      };

    case PRODUCT_ADD_NEW_ERROR:
    case PRODUCT_GET_PAGING_ERROR:
    case PRODUCT_GET_BY_ID_ERROR:
    case PRODUCT_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return { ...state };
  }
}