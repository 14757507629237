import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, Button
} from "reactstrap";
import SimpleBar from "simplebar-react";

import { withTranslation } from "react-i18next";
import { notificationService } from "services/notification-service";
import { getDayDifference, SEVERITY, toastMessage } from "helpers/utils";
import NotificationModal from "containers/notification/NotificationModal";
import ViewNotificationModal from "../../../pages/AlertNotification/ViewNotificationModal";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      page: 1,
      pageSize: 3,
      item: null,
      items: [],
      unreadIds: [],
      pageCount: 0,
      modalIsOpen: false,
      modalViewNotificationIsOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalIsOpen: !prevState.modalIsOpen,
    }));
  };
  
  toggleViewNotificationModal = () => {
    this.setState(prevState => ({
      modalViewNotificationIsOpen: !prevState.modalViewNotificationIsOpen,
    }))
  }
  
  componentDidMount() {
    this.populateNotification();
    // this.intervalId = setInterval(() => {
    //   this.populateUnsendNotification();
    //   // console.log("Interval to populate unsend notification");
    // }, 1000 * 10);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  populateUnsendNotification = () => {
    const { pageSize } = this.state;
    notificationService
      .getUsernotificationRequest({ page: null, isSend: false, pageSize })
      .then(res => {
        const { unsendIds, data } = res;
        data.forEach(item => {
          const { notification } = item;
          const { title, content, severity } = notification;
          switch (severity) {
            case 0:
              toastMessage({ title, text: content, severity: SEVERITY.INFO });
              break;
            case 1:
              toastMessage({
                title,
                text: content,
                severity: SEVERITY.SUCCESS,
              });
              break;
            case 2:
              toastMessage({
                title,
                text: content,
                severity: SEVERITY.WARNING,
              });
              break;
            case 3:
              toastMessage({
                title,
                text: content,
                severity: SEVERITY.DANGER,
              });
              break;
          }
        });

        notificationService
          .updateUsernotificationRequest(unsendIds)
          .then(res => {
            // console.log("update to sent is done");
          });
      });
  };

  populateNotification = () => {
    const { page, pageSize } = this.state;
    notificationService
      .getUsernotificationRequest({ page: null, pageSize })
      .then(res => {
        const { unreadIds, data, pageCount } = res;

        this.setState({
          items: data,
          unreadIds,
          pageCount,
        });
      });
  };

  handleLoadMore = () => {
    const { page, items, pageSize } = this.state;
    let newPage = page + 1;
    notificationService
      .getUsernotificationRequest({ page: newPage, pageSize })
      .then(res => {
        const { unreadIds, data } = res;
        this.setState({
          items: items.concat(data),
          page: newPage,
          unreadIds,
        });
      });
  };

  handleReadNotification = item => {
    const { id } = item;
    notificationService.readUsernotificationRequest(id).then(res => {
      this.updateUnreadCount(id);
      this.updateAllUnreadCount();
      if (!this.state.modalViewNotificationIsOpen) {
        this.setState({
          modalIsOpen: true,
          item,
        });
      }
      this.populateNotification();
    });
  };

  updateUnreadCount = (id) => {
    this.setState(prevState => ({
      unreadIds: prevState.unreadIds.filter(itemId => itemId !== id)
    }));
  };

  updateAllUnreadCount = () => {
    this.setState({
      unreadIds: [],
    });
  };

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }
  render() {
    const { unreadIds, items } = this.state;
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className="bx bx-bell bx-tada" />
            <span className="badge bg-danger rounded-pill">
              {unreadIds.length}
            </span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Notifications")} </h6>
                </Col>
                <div className="col-auto">
                <Link to="#" className="small" 
                  onClick={this.toggleViewNotificationModal}>
                    {" "}
                    View All
                </Link>
                </div>
              </Row>
            </div>

            <SimpleBar style={{ height: "230px" }}>
              {items.map((item, index) => {
                const { isRead, notification } = item;
                const { title, content, severity, date } = notification;
                var days = getDayDifference(new Date(), new Date(date));

                return (
                  <div
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleReadNotification(item)}
                    className="notification-item"
                  >
                    <div className="media">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-18">
                          {severity === 0 && <i className="fas fa-info" />}
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">{title}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{content}</p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" /> {days} days
                            ago
                          </p>
                        </div>
                      </div>
                      {!isRead && (
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                            }}
                          >
                            <i className="fas fa-circle text-primary"></i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </SimpleBar>
            {this.state.page !== this.state.pageCount && (
              <div className="p-2 border-top d-grid">
                <Link
                  className="btn btn-sm btn-link font-size-14 text-center"
                  to="#"
                  onClick={this.toggleViewNotificationModal}
                >
                  <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                  <span key="t-view-more">{this.props.t("View More..")}</span>
                </Link>
              </div>
            )}
          </DropdownMenu>
        </Dropdown>
        
        {this.state.item ? (
          <NotificationModal
            toggle={this.toggleModal}
            item={this.state.item}
            isOpen={this.state.modalIsOpen}
          />
        ) : null} 
        
        <ViewNotificationModal
           isOpen={this.state.modalViewNotificationIsOpen}
           toggle={this.toggleViewNotificationModal}
           onReadNotification={this.handleReadNotification}
           updateUnreadCount={this.updateUnreadCount}
           updateAllUnreadCount={this.updateAllUnreadCount} 
        />
      </React.Fragment>
    );
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(NotificationDropdown);
