import React, { Component } from "react";
import { Badge, Card, CardBody } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import jwt from "jsonwebtoken";
import moment from 'moment';

const USD_TO_WON_RATE = 1100;

export const decodeJwt = token => {
  if (!token) {
    console.log("Cannot decode empty token.");
    return null;
  }
  
  return jwt.decode(token);
};

export const getMaxValue = values => {
  return Math.max.apply(Math, values);
};

export const timestampFileName = () => {
  var today = new Date();
  const fileName = `${today.getFullYear()}${
    today.getMonth() + 1
  }${today.getDate()}${today.getHours()}${today.getMinutes()}`;

  return fileName;
};

export const handleDateChange = (obj, date, name) => {
  obj.setState({ [name]: date });
};

export const displayPaymentStatus = order => {
  const { paymentStatus, paymentStatusName } = order;
  let color = "info";

  switch (paymentStatus) {
    case 0:
      color = "primary";
      break;
    case 1:
      color = "info";
      break;
    case 2:
      color = "success";
      break;
    case 3:
      color = "danger";
      break;
  }

  return (
    <Badge color={color}>
      <span>{paymentStatusName}</span>
    </Badge>
  );
};

export const displayAlertNotificationStatus = alertNotification => {
  const { status, statusName } = alertNotification;
  let color = "info";

  switch (status) {
    case 0: // Draft
      color = "info";
      break;
    case 1: // Pending
      color = "warning";
      break;
    case 2: // Completed
      color = "success";
      break;
    case 3: // Closed
      color = "dark";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayDeliveryStatus = order => {
  const { deliveryStatus, deliveryStatusName } = order;
  let color = "info";

  switch (deliveryStatus) {
    case 0:
      color = "warning";
      break;
    case 1:
      color = "primary";
      break;
    case 5:
      color = "success";
      break;
    case 6:
      color = "danger";
      break;
    case 7:
      color = "dark";
      break;
  }

  return (
    <Badge color={color}>
      <span>{deliveryStatusName}</span>
    </Badge>
  );
};

export const getDeliveryStatusColor = order => {
  const { deliveryStatus } = order;
  let color = "info";

  switch (deliveryStatus) {
    case 0:
      color = "warning";
      break;
    case 1:
      color = "primary";
      break;
    case 5:
      color = "success";
      break;
    case 6:
      color = "danger";
      break;
    case 7:
      color = "dark";
      break;
  }
  
  return color;
};


export const getDeliveryStatusColorCode = (order) => {
  const { deliveryStatus, isPickup } = order;
  let color = "#50A5F1";

  if (isPickup && deliveryStatus === 0) return `#FF00FF`

  switch (deliveryStatus) {
    case 0:
      color = "#F1B44C";
      break;
    case 1:
      color = "#556EE6";
      break;
    case 5:
      color = "#34C38F";
      break;
    case 6:
      color = "#F46A6A";
      break;
    case 7:
      color = "#343A40";
      break;
  }

  return color;
}

export const getExpiryDateColorClass = (expiryDate) => {

  const now = moment().utc(); 
  const expiration = moment(expiryDate); 
  const fullMonths = expiration.diff(now, 'months', true);
  if (fullMonths <= 3) {
    return 'bg-danger'; 
  } else if (fullMonths > 3 && fullMonths <= 6) {
    return 'bg-warning'; 
  } else if (fullMonths > 6) {
    return 'bg-primary'; 
  } else {
    return 'bg-info'; 
  }
};

export const getExpiryDateColorBorder = (expiryDate) => {
  const now = moment().utc();
  
  const expiration = moment(expiryDate);
  const fullMonths = expiration.diff(now, 'months', true);

  if (fullMonths <= 3 ) {
    return 'border-danger'; 
  } else if (fullMonths > 3 && fullMonths <= 6){
    return 'border-warning'; 
  } else if (fullMonths > 6){
    return 'border-primary'; 
  }
  else {
    return 'border-info'; 
  }
};

export const displayRequestDeliveryToDoorStatus = order => {
  const { requestDeliveryToDoorStatus, requestDeliveryToDoorStatusName } =
    order;
  let color = "info";

  switch (requestDeliveryToDoorStatus) {
    case 0:
      color = "info";
      break;
    case 1:
      color = "warning";
      break;
    case 2:
      color = "success";
      break;
    case 3:
      color = "danger";
      break;
    case 4:
      color = "dark";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{requestDeliveryToDoorStatusName}</span>
    </Badge>
  );
};

export const displayVerifyStatus = order => {
  const { verifiedStatus, verifiedStatusName } = order;
  let color = "info";

  switch (verifiedStatus) {
    case 0:
      color = "warning";
      break;
    case 1:
      color = "success";
      break;
    case 2:
      color = "danger";
      break;
  }

  return (
    <Badge color={color}>
      <span>{verifiedStatusName}</span>
    </Badge>
  );
};

export const displayNotificationStatus = item => {
  const { status, statusName } = item;
  let color = "info";

  switch (status) {
    case 1:
      color = "success";
      break;
    case 0:
      color = "warning";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayTransportStatus = transport => {
  const { status, statusName } = transport;
  let color = "info";

  switch (status) {
    case 0:
      color = "info";
      break;
    case 1:
      color = "warning";
      break;
    case 2:
      color = "primary";
      break;
    case 3:
      color = "success";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayInvoicePaymentStatus = invoice => {
  const { status, statusName } = invoice;
  let color = "info";

  switch (status) {
    case 0:
      color = "primary";
      break;
    case 1:
      color = "info";
      break;
    case 2:
      color = "success";
      break;
    case 3:
      color = "danger";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayDeliveryOrderStatus = transport => {
  const { status, statusName } = transport;
  let color = "info";

  switch (status) {
    case 0:
      color = "info";
      break;
    case 1:
      color = "warning";
      break;
    case 2:
      color = "success";
      break;
    case 3:
      color = "danger";
      break;
    case 4:
      color = "dark";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayPickupPaymentStatus = order => {
  const { pickupPaymentStatus, pickupPaymentStatusName } = order;
  let color = "info";

  switch (pickupPaymentStatus) {
    case 0:
      color = "danger";
      break;
    case 1:
      color = "success";
      break;
  }

  return (
    <>
      <Badge color={color}>
        <span>{pickupPaymentStatusName}</span>
      </Badge>
    </>
  );
};

export const displayRoleBadge = name => {
  return (
    <Badge color={"primary"}>
      <span>{name}</span>
    </Badge>
  );
};

export const displayDeliveryPaymentStatus = order => {
  const { deliveryPaymentStatus, deliveryPaymentStatusName } = order;
  let color = "info";

  switch (deliveryPaymentStatus) {
    case 0:
      color = "danger";
      break;
    case 1:
      color = "success";
      break;
  }

  return (
    <Badge color={color}>
      <span>{deliveryPaymentStatusName}</span>
    </Badge>
  );
};

export const getUserProfile = () => {
  if (localStorage.getItem("token")) {
    const obj = JSON.parse(localStorage.getItem("token"));
    return decodeJwt(obj);
  }
};

export const hasAccess = permission => {
  let permissions = getPermissions();
  if (!permission) {
    return false;
  }
  if (!permissions) {
    console.log("unknown permissions, denying access", permission);
    return false;
  }
  const isGranted = permissions.includes(permission);
  if (!isGranted) {
    // console.log(`This user does not have ${permission} permission`);
  }

  return isGranted;
};

export const getPermissions = () => {
  let permissionsRaw = localStorage.getItem("permission");
  try {
    return permissionsRaw
      ? JSON.parse(permissionsRaw).map(a => {
          return a.systemName;
        })
      : [];
  } catch (err) {
    return [];
  }
};

export const deliveryStatusOptions = [
  { key: 2, value: 2, label: "In Cambodia Warehouse" },
  { key: 3, value: 3, label: "Completed" },
];

export const transprotDeliveryStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Shipping To Cambodia" },
  { key: 2, value: 2, label: "In Cambodia Warehouse", isDisabled: true },
  { key: 3, value: 3, label: "Completed", isDisabled: true },
];

export const sendOrderDeliveryStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Shipping To Cambodia" },
  { key: 2, value: 2, label: "In Cambodia Warehouse" },
  { key: 3, value: 3, label: "Completed" },
];

export const deliveryStatusOptions2 = [
  { key: 1, value: 1, label: "Shipping To Cambodia" },
  { key: 2, value: 2, label: "In Cambodia Warehouse" },
];

export const receiverDeliveryStatusOptions = [
  { key: 1, value: 1, label: "Shipping To Cambodia" },
  { key: 2, value: 2, label: "In Cambodia Warehouse" },
  { key: 3, value: 3, label: "Completed" },
];

export const convertWONtoUSD = won => {
  return Number(won ?? 0) / USD_TO_WON_RATE;
};

export const convertUSDtoWON = usd => {
  return Number(usd ?? 0) * USD_TO_WON_RATE;
};

export const paymentMethodOptions = [
  { key: 0, value: 0, label: "Cash" },
  { key: 1, value: 1, label: "ABA" },
  { key: 2, value: 2, label: "ACLEDA" },
  { key: 3, value: 3, label: "Kookmin Bank" },
  { key: 4, value: 4, label: "User Attached Payment" },
  { key: 5, value: 5, label: "Wallet" },
];

export const invoicePaymentStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Pending" },
  { key: 2, value: 2, label: "Confirmed" },
  { key: 3, value: 3, label: "Rejected" },
];

export const pickupPaymentStatusOptions = [
  { key: 0, value: 0, label: "Unpaid" },
  { key: 1, value: 1, label: "Paid" },
];

export const deliveryPaymentStatusOptions = [
  { key: 0, value: 0, label: "Unpaid" },
  { key: 1, value: 1, label: "Paid" },
];

export const shippingPaymentStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Invoiced" },
  { key: 2, value: 2, label: "Paid" },
  { key: 3, value: 3, label: "Unpaid" },
];

export const deliveryOrderStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "In Progress" },
  { key: 2, value: 2, label: "Completed" },
  { key: 3, value: 3, label: "Rejected" },
  { key: 4, value: 4, label: "Closed" },
];

export const saleOrderOptions = [
  { key: 0, value: true, label: "My Order" },
  { key: 1, value: false, label: "All Order" }
];

export const saleOrderInvoiceOptions = [
  { key: 0, value: true, label: "My Invoice" },
  { key: 1, value: false, label: "All Invoice" }
];

export const saleOrderStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 5, value: 5, label: "Confirmed" },
  // { key: 1, value: 1, label: "Pending", isDisabled: true},
  // { key: 2, value: 2, label: "Processing", isDisabled: true},
  { key: 3, value: 3, label: "Complete", isDisabled: true},
  { key: 4, value: 4, label: "Cancelled", isDisabled: true},
];

export const saleOrderStatusFilter = [
  { key: 0, value: 0, label: "Draft" },
  // { key: 1, value: 1, label: "Pending" },
  // { key: 2, value: 2, label: "Processing" },
  { key: 3, value: 3, label: "Complete" },
  { key: 4, value: 4, label: "Cancelled" },
  { key: 5, value: 5, label: "Confirmed" },
  { key: 6, value: 6, label: "Refund" },
  { key: 7, value: 7, label: "Refund Confirmed" },
];


export const saleOrderPaymentStatusOptions = [
  { key: 0, value: 0, label: "Pending" },
  // { key: 1, value: 1, label: "Authorized" },
  { key: 2, value: 2, label: "Paid" },
  // { key: 3, value: 3, label: "Partially Refunded" },
  // { key: 4, value: 4, label: "Refunded" },
  // { key: 5, value: 5, label: "Voided" },
  { key: 6, value: 6, label: "Unpaid" },
  { key: 7, value: 7, label: "Refund" },
  { key: 8, value: 8, label: "Cancelled" }
];

export const saleOrderInvoiceStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Invoiced" },
  { key: 2, value: 2, label: "Paid" },
  { key: 3, value: 3, label: "Unpaid" },
  { key: 4, value: 4, label: "Refund" },
  { key: 5, value: 5, label: "Cancelled" },
]

export const saleOrderPackingStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "In Progress" },
  { key: 2, value: 2, label: "Completed" }
];

export const roleOptions = [
  { value: "customer", label: "Customer" },
  { value: "seller", label: "Seller" },
  { value: "administrator", label: "Administrator" },
  { value: "system administrator", label: "System Administrator" },
];

export const saleOrderShippingStatusOptions = [
  { key: 0, value: 0, label: "Not yet shipped" },
  { key: 1, value: 1, label: "Shipping not required" },
  { key: 2, value: 2, label: "Partially shipped" },
  { key: 3, value: 3, label: "Shipped" },
  { key: 4, value: 4, label: "Delivered" },
];


export const saleOrderDeliveryStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Pending" },
  { key: 2, value: 2, label: "Awaiting Delivery", isDisabled: true },
  { key: 3, value: 3, label: "On Delivery", isDisabled: true },
  { key: 4, value: 4, label: "Delivered", isDisabled: true  },
  { key: 5, value: 5, label: "Completed", isDisabled: true  },
  { key: 6, value: 6, label: "Cancelled", isDisabled: true  },
];
export const saleOrderDeliveryStatusFilterOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Pending" },
  { key: 2, value: 2, label: "Awaiting Delivery"},
  { key: 3, value: 3, label: "On Delivery"},
  { key: 4, value: 4, label: "Delivered" },
  { key: 5, value: 5, label: "Completed" },
  { key: 6, value: 6, label: "Cancelled" },
];

export const saleOrderTypeStatusOptions = [
  { key: 0, value: 0, label: "Default" },
  { key: 1, value: 1, label: "Facebook Page" },
  { key: 2, value: 2, label: "Walk In" },
  { key: 3, value: 3, label: "Whole Sale" },
  { key: 4, value: 4, label: "In-App Order" },
];

export const saleOrderPaymentOptions = [
  { key: 0, value: 0, label: "Cash" },
  { key: 1, value: 1, label: "ABA" },
  { key: 2, value: 2, label: "ACLEDA" },
  { key: 3, value: 3, label: "Kookmin Bank" },
  { key: 4, value: 4, label: "User Attached Payment" },
  { key: 5, value: 5, label: "Wallet" },
];

export const localDeliveryStatusOptions=[
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "In-Progress" },
  { key: 2, value: 2, label: "Completed" },
  { key: 3, value: 3, label: "Rejected" },
  { key: 4, value: 4, label: "Closed" },
]

export const walletTransactionStatusFilter = [
  { key: 0, value: 0, label: "Pending" },
  { key: 1, value: 1, label: "Completed" },
  { key: 2, value: 2, label: "Fails" },
];

export const walletTransactionFilter = [
  { key: 0, value: 0, label: "Deposit" },
  { key: 1, value: 1, label: "Withdrawal" },
  { key: 2, value: 2, label: "Transfer" },
];

export const toastMessage = message => {
  const { title, text, severity } = message;

  toastr.options = {
    timeOut: 3000,
  };

  switch (severity) {
    case SEVERITY.INFO:
      toastr.info(text, title);
      break;
    case SEVERITY.DANGER:
      toastr.error(text, title);
      break;
    case SEVERITY.SUCCESS:
      toastr.success(text, title);
      break;
    case SEVERITY.WARNING:
      toastr.warning(text, title);
      break;
  }
};

export const SEVERITY = {
  SUCCESS: "SEVERITY_SUCCESS",
  INFO: "SEVERITY_INFO",
  WARNING: "SEVERITY_WARNING",
  DANGER: "SEVERITY_DANGER",
};

export const getDayDifference = (date1, date2) => {
  // To calculate the time difference of two dates
  var differenceInTime = date1.getTime() - date2.getTime();

  // To calculate the no. of days between two dates
  var differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.floor(differenceInDays);
};

export const getDaysOverDeadline = deadline => {
  return getDayDifference(new Date(), deadline);
};

export const getDaysLeftBeforeDeadline = deadline => {
  return getDayDifference(deadline, new Date());
};

export const displayInvoicePayments = item => {
  const {
    countConfirmedPayment,
    countDraftPayment,
    countPendingPayment,
    countRejectedPayment,
  } = item;
  return (
    <div>
      {countDraftPayment > 0 && (
        <Badge className="p-1 me-1" color="info">
          {countDraftPayment} Draft
        </Badge>
      )}
      {countPendingPayment > 0 && (
        <Badge className="p-1 me-1" color="warning">
          {countPendingPayment} Pending
        </Badge>
      )}
      {countConfirmedPayment > 0 && (
        <Badge className="p-1 me-1" color="success">
          {countConfirmedPayment} Confirmed
        </Badge>
      )}
      {countRejectedPayment > 0 && (
        <Badge className="p-1 me-1" color="danger">
          {countRejectedPayment} Rejected
        </Badge>
      )}
    </div>
  );
};

export const formatItemOptionLabel = ({ label, barcode, price, expiryDate, quantity, productImages }) => {
  let productImage = productImages && productImages.length > 0 ? productImages[0].url : null;
  const calculateDateDifference = (expiryDate) => {
    const now = moment(); 
    const expiration = moment(expiryDate); 
    const totalMonths = expiration.diff(now, 'months', true); 
    const fullMonths = Math.abs(Math.floor(totalMonths)); 
    const formattedExpiryDate = expiration.format('DD-MMM-YYYY');

    if (fullMonths > 0) {
      return `${formattedExpiryDate} (${fullMonths} month${fullMonths > 1 ? 's' : ''})`;
    } else if (fullMonths === 0) {
      return `${formattedExpiryDate} (Due this month)`;
    } else {
      return `${formattedExpiryDate} (${Math.abs(fullMonths)} month${Math.abs(fullMonths) > 1 ? 's' : ''})`;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="d-flex justify-content-center">
        {productImage ? (
          <img
            style={{ width: "80px", height: "50px", objectFit: "contain" }}
            src={productImage}
            alt="Product"
          />
        ) : (
          <div className="avatar-order">
            <i className="fas fa-solid fa-cube text-primary"></i>
          </div>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }} className={"ps-2"}>
        <p className={"mb-0"}>
          <strong>{label}</strong>
        </p>
        <p className={"mb-0 font-size-13"}>
          <span className={"me-2"}>Barcode</span>:<span className={"ms-2"}>{barcode}</span>
        </p>
        <p className={"mb-0 font-size-13"}>
          <span className={"me-2"}>Price</span>:<span className={"ms-2"}>{price}</span>
        </p>
        <p className={"mb-0 font-size-13"}>
          <span className={"me-2"}>Expiry Date</span>:<span className={"ms-2"}>{ calculateDateDifference (expiryDate)}</span>
        </p>
      </div>
    </div>
  );
};




export const customSingleValue = ({
                                    children,
                                    data,
                                    ...props
                                  }) => {
  const { label } = data
  return <components.SingleValue { ...props }>{ label }</components.SingleValue>
}

export const getCompanyBrand=()=>{
  let host = document.location.host;

  if (host.includes('portal.jueunlogistics.com')) {
    return JLS_EXPRESS;
  }

  if (host.includes('portal-sandbox.jueunlogistics.com')) {
    return JLS_EXPRESS_SANDBOX;
  }
  
  if (host.includes('localhost')) {
    return JLS_EXPRESS_LOCAL;
  }

  return RETAIL_LOGISTICS;
}

export const getCompanyName = () => {
  let host = document.location.host;
  
  if (host.includes('portal.jueunlogistics.com')) {
    return 'JLS Express';
  }
  if (host.includes('localhost')) {
    return 'JLS Express Local';
  }
  
  return 'Retail Logistics';
}

export const isTokenExpired = (token) => {
  try {
    const decodedToken = jwt.decode(token); // Decode without verifying the signature
    if (!decodedToken || !decodedToken.exp) {
      console.error('Invalid token format.');
      return true; // Consider the token expired if it lacks an `exp` field
    }

    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return decodedToken.exp < currentTime; // Token is expired if `exp` is earlier than current time
  } catch (error) {
    console.error('Error decoding token:', error);
    return true; // Assume expired if decoding fails
  }
};

export const addDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
}

export const JLS_EXPRESS = "JLS_EXPRESS";
export const JLS_EXPRESS_SANDBOX = "JLS_EXPRESS_SANDBOX";
export const JLS_EXPRESS_LOCAL = "JLS_EXPRESS_LOCAL";
export const RETAIL_LOGISTICS = "RETAIL_LOGISTICS";

export const displaySaleOrderDeliveryStatus = item => {
  let color = ""
  const { deliveryStatus, deliveryStatusName } = item
  switch (deliveryStatus) {
    case 0:
      // NotYetShipped = 0,
      color = "info"
      break
    case 1:
      // ShippingNotRequired = 1,
      color = "warning"
      break
    case 2:
      // PartiallyShipped = 2,
      color = "primary"
      break
    case 3:
      // Shipped = 3,
      color = "success"
      break
    case 4:
      // Delivered = 4,
      color = "dark"
      break
    default:
      color='info'
      break;
  }
  return (
    <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
      <span>{ deliveryStatusName }</span>
    </Badge>
  )
}

export const getFriendlyDateTime = dateInput => {
  const inputMoment = moment.utc(dateInput).local();
  const now = moment.utc().local();

  if (!inputMoment.isValid()) {
    return 'Invalid date';
  }

  const diffInDays = now.diff(inputMoment, 'days');

  if (diffInDays === 0) {
    return "Today" // This will return something like "a few seconds ago", "5 minutes ago"
  } else if (diffInDays === 1) {
    return 'Yesterday';
  } else if (diffInDays < 30) {
    return inputMoment.fromNow(); // This will return days ago for recent dates
  } else {
    return "";
  }
};
