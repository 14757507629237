import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  Label,
  Row,
  Input,
} from "reactstrap";
import { hasAccess } from "helpers/utils";
import { permission } from "constants/permission";
import Select from "react-select";
import { userService } from "../../services/user-service";

const statusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Pending" },
  { key: 2, value: 2, label: "Completed" },
  { key: 3, value: 3, label: "Closed" },
];

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      title: "",
      isCustomSend: false,
      body: "",
      users: [],
      status: null,
      userOptions: [],

      submitted: false,
    };
  }

  handleOnInputChange=(value)=>{
    userService.getUsersRequest({ term: value, roleNames: []}).then(records => {
      if (records) {
        this.setState({
          userOptions: records.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: `${a.firstName} ${a.lastName} <${a.uniqueId}>`,
            };
          })
        })
      }
    }).then(() => {});
  }

  componentDidMount() {
    userService.getUsersRequest({ term: '', roleNames: []}).then(records => {
      if (records) {
        this.setState(prevState => ({
          ...prevState,
          userOptions: records.map(a => {
            return {
              key: a.id,
              value: a.id,
              label: `${a.firstName} ${a.lastName} <${a.uniqueId}>`,
            };
          })
        }))
      }
    }).then(() => {});
  }

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  handleSelectUserOption = (user)=>{
    this.setState({
      users: user
    })
  }
  
  onOpened = () => {
    const { item } = this.props;

    if (item) {
      this.setState({
        ...item,
        status: statusOptions.find((e) => e.value === item.status),
        users: item.users ? item.users.map(a =>{
          return {
            key: a.id,
            value: a.id,
            label: `${a.firstName} ${a.lastName} <${a.uniqueId}>`
          }
        }): null
      });
    } else {
      this.setState({
        id: NIL,
        title: "",
        body: "",
        isCustomSend: false,
        users: [],
        status: { key: 0, value: 0, label: "Draft" },
        submitted: false,
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { id, title, body, status, isCustomSend, users } = this.state;
    this.setState({
      submitted: true,
    });

    let isValid = title && body && status;

    if (isValid) {
      const item = {
        id,
        title,
        body,
        isCustomSend,
        users: users ? users.map(user =>{
          return {
            id: user.value,
            firstName:'',
            lastName: '',
            uniqueId: '',
          }
        }): null,
        status: status?.value,
      };

      this.props.onSubmit(item);
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted, isCustomSend } = this.state;

    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="">Title</Label>
                <Input
                  type="text"
                  name="title"
                  placeholder="Title"
                  className={submitted && !this.state.title ? "is-invalid" : ""}
                  value={this.state.title}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.name && (
                  <div className="invalid-feedback-custom">
                    Title is required.
                  </div>
                )}
              </FormGroup>

              <FormGroup className="mb-3">
                <Label>Content</Label>
                <Input type="textarea" rows="3" name="body" placeholder="Content" value={this.state.body} onChange={this.handleChange} />
              </FormGroup>

              <div className="mb-3">
                <Label>Status</Label>
                <Select
                  name="status"
                  value={this.state.status}
                  onChange={this.handleSelectChange}
                  options={statusOptions}
                  classNamePrefix="select2-selection"
                  isClearable
                />
                {submitted && !this.state.status && <div className="invalid-feedback-custom">Status is required.</div>}
              </div>

              <div className="form-check form-check-primary mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="isCustomSend"
                  checked={isCustomSend}
                  onChange={(e) => this.setState({ isCustomSend: e.target.checked })}
                />
                <label className="form-check-label" htmlFor="customCheckcolor1">
                  Is Custom Send?
                </label>
              </div>

              {isCustomSend ? (
                <div className="mb-3">
                  <Label>Customer</Label>
                  <Select
                    name="user"
                    value={this.state.users}
                    onChange={this.handleSelectUserOption}
                    options={this.state.userOptions}
                    onInputChange={this.handleOnInputChange}
                    classNamePrefix="select2-selection"
                    isClearable
                    isMulti
                  />
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
        {hasAccess(permission.alertNotification.write) && (
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
        )}
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
