import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AlertNotificationReview from "./AlertNotificationReview";

const ViewNotificationModal = props => {
  const { isOpen, toggle, onReadNotification, updateUnreadCount, updateAllUnreadCount } = props;
  return (
    <Modal 
      scrollable={true} 
      isOpen={isOpen} 
      toggle={toggle} 
      size="lg" 
      backdrop="static">
      <ModalHeader toggle={toggle}>
       View Notifications
      </ModalHeader>
      <ModalBody>
        <AlertNotificationReview 
          onReadNotification={onReadNotification}
          updateUnreadCount={updateUnreadCount}
          updateAllUnreadCount={updateAllUnreadCount}
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

ViewNotificationModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ViewNotificationModal;