import FileSaver from "file-saver";
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  PRODUCT_ADD_NEW,
  PRODUCT_DELETE,
  PRODUCT_GET_BY_ID,
  PRODUCT_GET_PAGING,
  PRODUCT_UPDATE,
} from './actionType';

import {
  addProductSuccess,
  addProductError,
  getPagingProductSuccess,
  getPagingProductError,
  getProductByIdSuccess,
  getProductByIdError,
  updateProductSuccess,
  updateProductError,
} from './actions';
import { del, get, getFile, post, postFile, put as update } from "../../helpers/api_helper";

// Product
const getPagingProductRequest = query => post("/api/Product/paging", query);
const addNewProductRequest = data => post("/api/Product", data);
const getProductByIdRequest = id => get(`/api/Product/${id}`);
const updateProductRequest = data => update("/api/Product", data);
const deleteProductRequest = id => del(`/api/Product/${id}`);

const getProductsToExcel = async (fileName , payload) => {
  return await postFile(`/api/Product/exportToExcel`,payload, {
    responseType: "blob",
  }).then(res => {
    if (res) {
      const { data } = res;
      FileSaver.saveAs(new Blob([data]), fileName);
    }
  });
};

function* addNewProduct({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(addNewProductRequest, data);
    yield put(addProductSuccess(response));
    history.push('/product')
    // response = yield call(getPagingProductRequest, queryParams);
    // yield put(getPagingProductSuccess(response));
  } catch (error) {
    yield put(addProductError(error.response.statusText));
  }
}

function* updateProduct({ payload }) {
  try {
    const { data, queryParams, history } = payload;
    let response = yield call(updateProductRequest, data);
    yield put(updateProductSuccess(response));
    history.push('/product')
    // response = yield call(getPagingProductRequest, queryParams);
    // yield put(getPagingProductSuccess(response));
  } catch (error) {
    yield put(updateProductError(error.response.statusText));
  }
}

function* getPagingProduct({ payload }) {
  try {
    let response = yield call(getPagingProductRequest, payload);
    yield put(getPagingProductSuccess(response));
  } catch (error) {
    yield put(getPagingProductError(error.response.statusText));
  }
}

function* getProductById({ payload }) {
  try {
    let response = yield call(getProductByIdRequest, payload);
    yield put(getProductByIdSuccess(response));
  } catch (error) {
    yield put(getProductByIdError(error.response.statusText));
  }
}

function* deleteProduct({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteProductRequest, data);

    response = yield call(getPagingProductRequest, queryParams);
    yield put(getPagingProductSuccess(response));
  } catch (error) {
    yield put(getPagingProductError(error.response.statusText));
  }
}

export default function* rootSaga() {
  yield takeEvery(PRODUCT_ADD_NEW, addNewProduct);
  yield takeEvery(PRODUCT_UPDATE, updateProduct);
  yield takeEvery(PRODUCT_GET_PAGING, getPagingProduct);
  yield takeEvery(PRODUCT_GET_BY_ID, getProductById);
  yield takeEvery(PRODUCT_DELETE, deleteProduct);
}

export {
  getPagingProductRequest,
  getProductsToExcel
}
