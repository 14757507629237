import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardTitle, Col, Row } from "reactstrap";
import ProductSummaryCard from "./ProductSummaryCard";
import { useSelector } from "react-redux";

const ProductExpirySummary = ({ item, title, onClick }) => {
  
  const { threeCount, sixCount, moreCount, noExpiringCount } = useSelector(
    (state) => state.product
  );

  const allCount = threeCount + sixCount + moreCount + noExpiringCount;
  
  return (
    <Card className="mb-0">
      <CardHeader className="bg-transparent border">
        <CardTitle>{ title }</CardTitle>
        <Row>
          <Col md={ 12 }>
            <div className="d-flex flex-row">
              {/* All Expiry Summary */ }
              <ProductSummaryCard
                color="success"
                onClick={() => onClick({ expiringTerm: "" })}
                title="All"
                iconName="fa-list"
                count={ allCount }
              />

              {/* Expiring within 3 Months */ }
              <ProductSummaryCard
                color="danger"
                onClick={() => onClick({ expiringTerm: "threeMonths" })}
                title="Expiring within 3 months"
                iconName="fa-list"
                count={ threeCount }
              />


              {/* Expiring within 6 Months */ }
              <ProductSummaryCard
                color="warning"
                onClick={() => onClick({ expiringTerm: "sixMonths" })}
                title="Expiring within 6 months"
                iconName="fa-check"
                count={ sixCount }
              />

              {/* Expiring in More than 6 Months */ }
              <ProductSummaryCard
                color="primary"
                onClick={() => onClick({ expiringTerm: "moreThan6Months" })}
                title="Expiring in more than 6 months"
                iconName="fa-times"
                count={ moreCount }
              />

              {/* No Expiry Date */ }
              <ProductSummaryCard
                color="info"
                onClick={() => onClick({ expiringTerm: "noExpiring" })}
                title="No expiry date"
                iconName="fa-times"
                count={ noExpiringCount }
              />
            </div>
          </Col>
        </Row>
      </CardHeader>
    </Card>
  );
};

ProductExpirySummary.propTypes = {
  product: PropTypes.object,
  title: PropTypes.string,
};

export default ProductExpirySummary;
