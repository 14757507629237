import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { notificationService } from "services/notification-service";
import CustomPagination from "components/Common/CustomPagination";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row
} from "reactstrap";
import AlertNotificationReviewCard from "./AlertNotificationReviewCard";
import { debounce } from "lodash";

const AlertNotificationReview = props => {
  const { onReadNotification, updateAllUnreadCount, updateUnreadCount } = props;
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10);
  const [ term, setTerm ] = useState("");
  const [ alertNotifications, setAlertNotifications ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ pageCount, setPageCount ] = useState(1);

  const debouncedFetchData = useCallback(
    debounce(term => {
      setTerm(term);
      setPage(1);
    }, 1000),
    []
  );

  const handleReadAllNotifications = () => {
    setAlertNotifications([]);

    notificationService.readAllUsernotifications().then(() => {
      updateAllUnreadCount();
      populateNotification();
    });
  };

  const populateNotification = () => {
    notificationService.getUsernotificationRequest({ page, pageSize, term })
      .then(res => {
        const { data, pageCount } = res;
        setAlertNotifications(data);
        setPageCount(pageCount);
      });
  };
  
  useEffect(() => {
    populateNotification();
  }, [ term, page, pageSize ]);

  return (
    <div>
      <Card className="mb-1">
        <CardHeader className="bg-transparent border-bottom">
          <Row className="g-1">
            <Col md={ 3 }>
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <Input type="text" placeholder="Quick Search ..."
                         onChange={ e => debouncedFetchData(e.target.value) } />
                  <i className="fas fa-search search-icon" />
                </div>
              </div>
            </Col>
            <Col md={ 9 }>
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="primary"
                  className="me-1"
                  onClick={ handleReadAllNotifications }
                >
                  Read All
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>
      </Card>

      { loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        alertNotifications.map(item => {
          return (
            <AlertNotificationReviewCard
              key={ item.id }
              item={ item }
              onReadNotification={ onReadNotification }
              updateUnreadCount={ updateUnreadCount }
            />
          );
        })
      ) }
      <Card>
        <CardBody className="py-2">
          <CustomPagination
            itemCount={ alertNotifications.length }
            currentPage={ page }
            totalPage={ pageCount }
            defaultPageSize={ pageSize }
            pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
            onChangePage={ i => setPage(i) }
            onPageSizeChange={ size => {
              setPage(1);
              setPageSize(size);
            } }
          />
        </CardBody>
      </Card>
    </div>
  );
};

AlertNotificationReview.propTypes = {
  updateAllUnreadCount: PropTypes.func,
  updateUnreadCount: PropTypes.func,
  productId: PropTypes.string.isRequired
};

export default withRouter(
  connect(null, {})(AlertNotificationReview)
);
