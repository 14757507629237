import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Collapse, Row } from "reactstrap";
import moment from "moment";

const AlertNotificationReviewCard = ({ item, onReadNotification, updateUnreadCount }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRead, setIsRead] = useState(item.isRead);

  useEffect(() => {
    setIsRead(item.isRead);
  }, [item]); 

  const handleToggle = () => {
    if (!isRead) {
      setIsRead(true);
      onReadNotification(item);
      updateUnreadCount();
    }
    setIsOpen(prev => !prev);
  };

  const getFriendlyDateTime = (dateInput) => {
    const inputMoment = moment.utc(dateInput).local();
    const now = moment.utc().local();

    if (!inputMoment.isValid()) return "Invalid date";

    const diffInDays = now.diff(inputMoment, "days");

    if (diffInDays === 0) return inputMoment.fromNow();
    if (diffInDays === 1) return "Yesterday";
    if (diffInDays <= 7) return inputMoment.fromNow();
    return inputMoment.format("DD-MMM-YYYY h:mm:ss a");
  };

  return (
    <Card className="mb-2 border border-1">
      <CardHeader className="bg-transparent border-bottom">
        <Row>
          <Col>
            <CardTitle>{item.notification?.title}</CardTitle>
            <CardSubtitle>
              <span className="text-muted">{getFriendlyDateTime(item.notification?.date)}</span>
            </CardSubtitle>
          </Col>
        </Row>

        <div style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}>
          {!isRead && (
            <span className="font-size-14 text-primary px-2" style={{ cursor: "pointer" }}>
              <i className="fas fa-circle"></i>
            </span>
          )}

          <span
            className="font-size-24 text-primary px-2"
            style={{ cursor: "pointer" }}
            onClick={handleToggle}
          >
            <i className={isOpen ? "fas fa-angle-up" : "fas fa-angle-down"}></i>
          </span>
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <Row>
            <Col md={12}>
              <div dangerouslySetInnerHTML={{ __html: item.notification?.content }} />
            </Col>
          </Row>
        </CardBody>
      </Collapse>
    </Card>
  );
};

AlertNotificationReviewCard.propTypes = {
  item: PropTypes.object.isRequired,
  onReadNotification: PropTypes.func.isRequired,
  updateUnreadCount: PropTypes.func.isRequired,
};

export default AlertNotificationReviewCard;